
import { getToken } from './token'
// 跳转官网
export function toiLaaS (path) {
  const query = {
    token: getToken()
  }
  // ?tokenInfo=${JSON.stringify(query)}
  // window.location.href = `https://192.168.3.55:8088/${path}`;
  // window.location.href = `https://test.ilaas.cn/${path}`;
  // window.location.href = `https://www.ilaas.cn/${path}`
  window.location.href = `${process.env.VUE_APP_BASE_API_ILAAS}/${path}`
}

// 参会者链接地址
export function participantLink (num) {
  // let urlPath = `https://192.168.3.55:8081?roomNumber=${num}`
  // const urlPath = `https://meeting.ilaas.cn?roomNumber=${num}`
  // let urlPath = `https://ilaas-room.ilaas.cn?roomNumber=${num}`
  let urlPath = `${process.env.VUE_APP_BASE_API_ROOM}?roomNumber=${num}`
  return urlPath
}

// dateNum为空，获取日期，type =1;Y-MM-DD;type=2;Y-MM-DD HH:mm:ss;type=3,Y年MM月DD日 星期几;
// dateNum格式为Y-MM-DD或者时间戳，返回格式化的日期。
export function getDate (type, dateNum) {
  var date
  if (dateNum === '') {
    return ''
  }
  if (dateNum != null) {
    // date = new Date(dateNum);
    var currentDate = new Date(dateNum)
    if (currentDate.toString() === 'Invalid Date') {
      var dateStringList = String(dateNum).split(/[- : \/]/)
      if (dateStringList.length >= 2) {
        // 字符串月份需要-1才能获得真正的月份
        dateStringList[1] = String(Number(dateStringList[1]) - 1)
      }
      date = new Date(...dateStringList.slice(0, 6))
    } else {
      date = currentDate
    }
  } else {
    date = new Date()
  }
  var Y = date.getFullYear()
  var M = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + parseInt(date.getMonth() + 1)
  var D = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
  var week = ['日', '一', '二', '三', '四', '五', '六'][date.getDay()]
  var H = date.getHours() > 9 ? date.getHours() : '0' + date.getHours()
  var m = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
  var s = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds()
  var time = ''
  if (type == 1) {
    time = Y + '-' + M + '-' + D
  } else if (type == 2) {
    time = Y + '-' + M + '-' + D + ' ' + H + ':' + m + ':' + s
  } else if (type == 3) {
    time = Y + '-' + M
  } else if (type == 4) {
    time = M + '/' + D
  } else if (type == 5) {
    time = Number(M) + '月' + Number(D) + '日'
  } else if (type == 6) {
    time = Y + ' 年 ' + M + ' 月 ' + D + ' 日 '
  } else if (type == 'week') {
    time = week
  } else {
    time = Y + ' 年 ' + M + ' 月 ' + D + ' 日 &nbsp;&nbsp;&nbsp; 星期' + week
  }
  return time
}

// 客户所属行业查询  传 num 参数 返回对应名称， 不传 返行业数组
export function getIndustry (num) {
  const newArr = [
    { label: '机构组织', value: 1 },
    { label: '农林牧渔', value: 2 },
    { label: '建筑建材', value: 3 },
    { label: '冶金矿产', value: 4 },
    { label: '石油化工', value: 5 },
    { label: '水利水电', value: 6 },
    { label: '交通运输', value: 7 },
    { label: '信息产业', value: 8 },
    { label: '机械机电', value: 9 },
    { label: '轻工食品', value: 10 },
    { label: '服装纺织', value: 11 },
    { label: '安全防护', value: 12 },
    { label: '环保绿化', value: 13 },
    { label: '旅游休闲', value: 14 },
    { label: '办公文教', value: 15 },
    { label: '电子电工', value: 16 },
    { label: '玩具礼品', value: 17 },
    { label: '居家用品', value: 18 },
    { label: '物资', value: 19 },
    { label: '包装', value: 20 },
    { label: '体育', value: 21 },
    { label: '办公', value: 22 },
    { label: '银行金融保险', value: 23 },
    { label: '互联网', value: 24 },
    { label: '专业服务', value: 25 },
    { label: '其他', value: 26 }
  ]
  if (num) {
    for (const key in newArr) {
      const item = newArr[key]
      if (num == item.value) {
        return item.label
      }
    }
  } else {
    return newArr
  }
}

// 客户性质查询  传 num 参数 返回对应名称， 不传 返客户性质数组
export function getNature (num) {
  const newArr = [
    { label: '国企', value: 1 },
    { label: '民企', value: 2 },
    { label: '外企', value: 3 },
    { label: '事业单位', value: 4 },
    { label: '政府机构', value: 5 },
    { label: '团体', value: 6 }
  ]
  if (num) {
    if (typeof num === 'number') {
      for (let key in newArr) {
        let item = newArr[key]
        if (num == item.value) {
          return item.label
        }
      }
    } else {
      for (let key in newArr) {
        let item = newArr[key]
        if (num == item.label) {
          return item.value
        }
      }
    }
  } else {
    return newArr
  }
}

// 案件节点查询  传 num 参数 返回对应名称， 不传 返案件节点数组
export function getCaseNodeList (num) {
  const newArr = [
    {label: "调解",value: 1},
    {label: "仲裁",value: 2},
    {label: "一审",value: 3},
    {label: "二审",value: 4},
    {label: "再审",value: 5},
  ]
  if (num) {
    for (const key in newArr) {
      const item = newArr[key]
      if (num == item.value) {
        return item.label
      }
    }
  } else {
    return newArr
  }
}

// 案件结果查询  传 num 参数 返回对应名称， 不传 返案件结果数组
export function getCaseResultList (num) {
  console.log(num,'num-----------')
  const newArr = [
    {label: "胜诉",value: 1},
    {label: "败诉",value: 2},
    {label: "部分胜诉",value: 3},
    {label: "实现客户预期",value: 4},
  ]
  if (num) {
    for (const key in newArr) {
      const item = newArr[key]
      if (num == item.value) {
        return item.label
      }
    }
  } else {
    return newArr
  }
}

// 结案节点查询  传 num 参数 返回对应名称， 不传 返结案节点数组
export function getCaseSettleList (num) {
  const newArr = [
    {label: "调解",value: 1},
    {label: "仲裁裁决",value: 2},
    {label: "一审裁决",value: 3},
    {label: "二审裁决",value: 4},
    {label: "再审裁决",value: 5},
  ]
  if (num) {
    for (const key in newArr) {
      const item = newArr[key]
      if (num == item.value) {
        return item.label
      }
    }
  } else {
    return newArr
  }
}

// 获取一周的时间  对应日期及周几 date:周内某个时间  initDate:周起始时间(日或一)
export function setWeekDate (date, initDate) {
  const newData = date || new Date()
  initDate = initDate || '一'
  const weekList = ['一', '二', '三', '四', '五', '六']
  // if (initDate == '一') {
  //   weekList.push('日')
  // } else if (initDate == '日') {
  //   weekList.unshift('日')
  // }
  if (initDate == '日') {
    weekList.unshift('日')
  } else {
    weekList.push('日')
  }
  const weekDateList = []
  let is_date = newData.getTime()
  const newWeek = getDate('week', newData)
  const weekIdx = weekList.findIndex((item) => {
    return item == newWeek
  })
  const weekIdx2 = 7 - weekIdx
  for (var i = 0; i < weekIdx; i++) {
    is_date -= 1000 * 60 * 60 * 24
    weekDateList.unshift({
      label: '周' + getDate('week', is_date),
      date: getDate(1, is_date),
      showDate: getDate(4, is_date),
      showDate2: getDate(5, is_date)
    })
  }
  is_date = newData.getTime()
  weekDateList.push({
    label: '周' + getDate('week', is_date),
    date: getDate(1, is_date),
    showDate: getDate(4, is_date),
    showDate2: getDate(5, is_date)
  })
  for (var i = 1; i < weekIdx2; i++) {
    is_date += 1000 * 60 * 60 * 24
    weekDateList.push({
      label: '周' + getDate('week', is_date),
      date: getDate(1, is_date),
      showDate: getDate(4, is_date),
      showDate2: getDate(5, is_date)
    })
  }
  return weekDateList
}
// elment 日期插件限制不能选未来时间
export function set_PickerOption (date) {
  // disabledDate 为true表示不可选,false表示可选
  if (date !== undefined) {
    date = new Date(date).getTime() - 24 * 60 * 60 * 1000
  } else {
    date = 0
  }
  const obj = {}
  obj.disabledDate = function (time) {
    var num = time.getTime()
    if (num > Date.now() - 8.64e6 || num < date) {
      return true
    }
  }
  return obj
}
// elment 日期插件限制不能选过去时间
export function set_PickerOption1 (date) {
  // disabledDate 为true表示不可选,false表示可选
  const obj = {}
  const date1 = new Date().getTime() - 24 * 60 * 60 * 1000
  if (date !== undefined) {
    date = new Date(date).getTime()
  } else {
    date = '9999-12-31'
  }
  // console.log(date)
  obj.disabledDate = function (time) {
    var num = time.getTime()
    if (num < date1 || num > date) {
      return true
    }
  }
  return obj
}
// elment 日期插件限制只能选择区间内时间
export function set_PickerOption2 (date, date2) {
  // disabledDate 为true表示不可选,false表示可选
  const obj = {}
  date1 = date1 ? new Date(date1).getTime() - 24 * 60 * 60 * 1000 : new Date().getTime() - 24 * 60 * 60 * 1000
  date2 = date2 ? new Date(date2).getTime() : new Date('9999-12-31').getTime()
  // console.log(date)
  obj.disabledDate = function (time) {
    var num = time.getTime()
    if (num < date1 || num > date2) {
      return true
    }
  }
  return obj
}
// 读取图片（或文件）转成base64
export function readFileToBase64 (file) {
  return new Promise((resolve, reject) => {
    try {
      // 读取信息
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // 转base64结果
        const base64Url = reader.result;
        resolve(base64Url);
      }
      reader.onerror = (err) => {
        reject(err);
      }
    } catch (error) {
      reject(error);
    }
})
}

//计算一段文本的长度px.
export function getstrLength (str){
  var a = document.createElement('A');
  if(str!==undefined){
    a.innerText = str;
  }else{
    a.innerText = '';
  }

  $(document.body).append(a);
  var w = a.offsetWidth;
  $(a).remove();
  return w;
}
