<template>
  <router-view v-if="isRouterAlive"/>
</template>
<script>
import { core } from './config/pluginInit'

export default {
  name: 'App',
  data() {
    return {
      isRouterAlive: true,
    }
  },
  components: {
  },
  mounted () {
    core.mainIndex();
    document.documentElement.style.setProperty("--iq-primary", "#6475c7");
    document.documentElement.style.setProperty(
      "--iq-primary-light",
      "#7abbf3"
    );
    document.documentElement.style.setProperty(
      "--iq-bg-light-color",
      "#eaf5ff"
    );
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function() {
         this.isRouterAlive = true
      })
    },
  },
}
</script>
<style lang="scss">
  @import "assets/scss/style.scss";
  p {
    margin-bottom: 0 !important;
  }
  .all-crumbs {
    font-size: 14px;
    color: #333;
    padding-bottom: 10px;
    border-bottom: 1px solid #E7E7E7;
  }
</style>
