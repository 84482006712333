
const common ={
  //获取当前时间
  getTime(){
    var date = new Date();
    var seperator1 = "-";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate;
    return currentdate
  },
  //获取过去周 月 年
  getFormerlyTime(val,date = new Date()) {
    date.setDate(date.getDate() - val);
    var seperator1 = "-";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    let time  = year + seperator1 + month + seperator1 + strDate;
    return time 
  },
  //转地区时间
  shiftGMT(str){
    str = String(str).replace(/ GMT.+$/, '');
    var d = new Date(str);
    var a = [d.getFullYear(), d.getMonth() + 1, d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds()];
    for(var i = 0, len = a.length; i < len; i ++) {
      if(a[i] < 10) {
        a[i] = '0' + a[i];
      }
    }
    str = a[0] + '-' + a[1] + '-' + a[2] + ' ' + a[3] + ':' + a[4] + ':' + a[5];
    return str
  },
  //搜索文字高亮
  brightenKeyword(val, keyword) {
    if (keyword.length > 0) {
      let keywordArr = keyword.split("");
      val = val + "";
      keywordArr.forEach((item) => {
        if (val.indexOf(item) !== -1 && item !== "") {
          val = val.replace(
            new RegExp(item, "g"),
            '<font color="#f75353">' + item + "</font>"
          );
        }
      });
      return val;
    } else {
      return val;
    }
  },
   //获取位数随机码
   getCode(num){
    var str="23QWERTYUIOPASDFGHJKLZXCVBNM1456789zxcvbnmasdfghjklqwertyuiop0"
    var res1=''
    for(var i=0;i<num;i++){
        res1+=str[Math.floor(Math.random()*str.length)];
    }
    return res1
  },
  //只能输入数字
  inputChangeNum:function(e){
    const o = e.target;
    o.value = o.value.replace(/[^\d]/g, ''); 
　　return o.value;
  },
  //只能输入中文
  inputChangeZh(e){
    const o = e.target;
    o.value = o.value.replace(/[^\u4E00-\u9FA5]/g, ''); // 清除除了中文以外的输入的字符
　　return o.value;
  },
  //禁止输入英文
  inputNoEn(e){
    const o = e.target;
    o.value = o.value.replace(/^[\u0391-\uFFE5A-Za-z]/g, ''); // 
　　return o.value;
  },
  //禁止输入中文
  inputNoZh(e){
    const o = e.target;
    o.value = o.value.replace(/[\u4E00-\u9FA5]/g, ''); // 
　　return o.value;
  },
  //获取token
  getToken(){
    const COOKIE_TOKEN =  localStorage.getItem('TIME_TOKEN')
    return COOKIE_TOKEN
  },
  //拦截错误
  getError(err){
    var msg = String(err).split(':')[1]
    return msg
  },
}
export default common;