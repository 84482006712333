import '@babel/polyfill'
import Vue from 'vue'
import 'mutationobserver-shim'
import './Utils/fliter'
import './config/firebase'
import App from './App.vue'
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import './plugins'
import './registerServiceWorker'
import AlgoliaComponents from 'vue-instantsearch'
import i18n from './i18n'
import './directives'
import common from '@/Utils/common'
import jquery from 'jquery'
import echarts from 'echarts';
// import UUID from "uuid";
// Vue.use(UUID);
Vue.prototype.$ = jquery
Vue.prototype.$common = common;

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/base.css';
Vue.use(ElementUI);
import Base64 from './Utils/base64'
Vue.prototype.$Base64 = Base64;
import {
  getDate,
  getIndustry,
  getNature,
  getCaseNodeList,
  getCaseResultList,
  getCaseSettleList,
  setWeekDate,
  toiLaaS,
  set_PickerOption,
  set_PickerOption1,
  set_PickerOption2,
  participantLink,
  readFileToBase64,
  getstrLength
} from '@/Utils/index'

Vue.prototype.$echarts = echarts;
Vue.prototype.$getDate = getDate;
Vue.prototype.$getIndustry = getIndustry;
Vue.prototype.$getNature = getNature;
Vue.prototype.$getCaseNodeList = getCaseNodeList;
Vue.prototype.$getCaseResultList = getCaseResultList;
Vue.prototype.$getCaseSettleList = getCaseSettleList;
Vue.prototype.$setWeekDate = setWeekDate;
Vue.prototype.$toiLaaS = toiLaaS;
Vue.prototype.$set_PickerOption = set_PickerOption;
Vue.prototype.$set_PickerOption1 = set_PickerOption1;
Vue.prototype.$set_PickerOption2 = set_PickerOption2;
Vue.prototype.$participantLink = participantLink;
Vue.prototype.$readFileToBase64 = readFileToBase64;
Vue.prototype.$getstrLength = getstrLength;
import axios from 'axios'
// import Base64 from '@/Utils/base64'
global.Raphael = Raphael

// Vue.prototype.$Base64 = Base64;
Vue.prototype.$http = axios
Vue.prototype.CX_Api = 'https://api.ai-hrcompliance.com/web-apps/apps/api/documents/api.js' //畅写地址

Vue.use(AlgoliaComponents)
Vue.config.productionTip = false

//获取table 高
Vue.prototype.$getTableHeight = function (size,elemnt) {
  let h = size?size:75;
  let ele = elemnt?elemnt:'el-main';
  // setTimeout()
  return $('.'+ele)[0].clientHeight - h;
};
//计算表格内容宽度
Vue.prototype.$getTableCellLength = function (obj) {
  // console.log(JSON.parse(JSON.stringify(obj)));
  var W = obj.W;//容器宽度
  var otherWidth = obj.hasOwnProperty('W2')?obj.W2:0;//循环之外的列宽。
  var head = obj.head;//表头数据
  var currentData = obj.data;//表体数据

  var lengthArr = [];
  var arrhead = {};
  var arrtotal = {};

  for(var i = 0;i <head.length;i++){
    var text = head[i]['label'];
    let prop = head[i]['prop'];
    arrhead[prop] = this.$getstrLength(text)
  }
  var keys = Object.keys(arrhead);
  // console.log(arrhead)
  lengthArr.push(arrhead);//计算表头长度放入数组
  for(var i = 0;i < currentData.length;i++){
    var obj={};
    for(var k in currentData[i]){
      var text = currentData[i][k];
      if(keys.indexOf(k) > -1){
        obj[k] = this.$getstrLength(text)
      }
    }
    lengthArr.push(obj);//计算表体长度放入数组内
  }
  for(var i = 0;i < keys.length;i++){
    arrtotal[keys[i]] = [];
    for(var j = 0;j < lengthArr.length;j++){
      for(var k in lengthArr[j]){
        if(k === keys[i]){
          arrtotal[keys[i]].push(lengthArr[j][k])
        }
      }
    }
  }

  for(var i in arrtotal){
    arrtotal[i] = Math.max.apply(Math,arrtotal[i]);
  }

  if(W!==undefined){//根据容器宽度补全多余部分。
    function sum(obj) {
      var s = 0;
      for (var i in obj) {
        s += parseInt(obj[i]);
      }
      return s;
    }
    var w = sum(arrtotal);
    var len = head.length;
    var aver = 20;
    if((W - otherWidth - w ) > 0){
      aver = Math.ceil((W - otherWidth - w) / len);
    }
    aver = aver < 20 ? 20 : aver;
    for(var i in arrtotal){
      arrtotal[i] = arrtotal[i] + aver;
    }
  }
  // console.log(W)
  // console.log(eval(Object.values(arrtotal).join('+')))
  return arrtotal;
};
const vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
