import getters from './getters'
let id = 0;
const state = {
  userInfo: null,
  ossInfo: null,
  conferenceInfo: null || JSON.parse(localStorage.getItem('conferenceInfo')),
  // conferenceInfo: null,
  browserInfo: null, // 用户浏览器
  serveReportInfo: { // 服务报告律师数据
    name: "某某案件",
    _info: {}, // 统计好的数据
    _pageList: [ // 所有子页面
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover1.png'),
        title: "首页",
        pageName: "page01",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover2.png'),
        title: "目录",
        pageName: "page02",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover3.png'),
        title: "律师个人介绍",
        pageName: "page03",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover4.png'),
        title: "服务客户信息",
        pageName: "page04",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover5.png'),
        title: "服务客户信息",
        pageName: "page05",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover6.png'),
        title: "服务客户信息",
        pageName: "page06",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover7.png'),
        title: "案件代理服务",
        pageName: "page07",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover8.png'),
        title: "案件代理服务",
        pageName: "page08",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover11.png'),
        title: "专项服务",
        pageName: "page11",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover9.png'),
        title: "常年法律顾问服务",
        pageName: "page09",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover10.png'),
        title: "常年法律顾问服务",
        pageName: "page10",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover12.png'),
        title: "客户满意度",
        pageName: "page12",
      },
      {
        id: id++,
        img: require('@/assets/serve-report/pages/cover/cover13.png'),
        title: "",
        pageName: "page13",
      },
    ],
    _comps: [ // 组件名集合
      "page01",
      "page02",
      "page03",
      "page04",
      "page05",
      "page06",
      "page07",
      "page08",
      "page11",
      "page09",
      "page10",
      "page12",
      "page13",
    ],
    upImgList: [], // 上传的图片
    bgImg: [], //上传的背景图片
  },
};

const mutations = {
  SET_USER_INFO(state, info) {
    state.userInfo = info;
  },
  SET_OSS_INFO(state, info) {
    state.ossInfo = info;
  },
  SET_CONFERENCE_INFO(state, info) {
    localStorage.setItem('conferenceInfo', JSON.stringify(info));
    state.conferenceInfo = info;
  },
  SET_NOWADAY_BROWSER(state, info) {
    state.browserInfo = info;
  },
  SET_SERVE_REPORT_INFO(state, info) {
    state.serveReportInfo = info;
  }
};
const actions = {
  setUserInfo({ commit }, info) {
    commit('SET_USER_INFO', info);
  },
  // oss 阿里云数据
  setOssInfo({ commit }, info) {
    commit('SET_OSS_INFO', info);
  },
  // 会议室数据
  setConferenceInfo({ commit }, info) {
    commit('SET_CONFERENCE_INFO', info);
  },
  // 当前用户浏览器
  setNowadayBrowser({ commit }, info) {
    commit('SET_NOWADAY_BROWSER', info);
  },
  // 设置服务报告数据
  setServeReportInfo({ commit }, info) {
    commit('SET_SERVE_REPORT_INFO', info);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
